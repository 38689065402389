import { makeStyles, shorthands } from '@fluentui/react-components';
import { Send16Regular } from '@fluentui/react-icons';
import React from 'react';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    left: 0,
    ...shorthands.borderTop('1px', 'solid', '#B2B2B2'),
    ...shorthands.padding('8px', '16px'),
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('8px'),
    backgroundColor: '#fff',
  },
  input: {
    ...shorthands.border('none'),
    outlineColor: 'transparent',
    height: '20px',
    width: '100%',
  },
  button: {
    height: '32px',
    width: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...shorthands.border('none'),
    outlineColor: 'transparent',
    backgroundColor: '#50BCBD',
    ...shorthands.borderRadius('50%'),
  },
});

export const ChatInput = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <input
        type="text"
        className={styles.input}
        placeholder="Enter your message..."
        aria-label="Ask AI chat bot a question"
      />
      <button className={styles.button} aria-label="Send message">
        <Send16Regular color="#fff" aria-hidden="true" />
      </button>
    </div>
  );
};
