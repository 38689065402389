import { ReportAction, handleError } from '@utils-global';
import { deleteMail, moveMailToSpam } from '@data';

const DELAY = 3000;
const actionNotificationMap = {
  [ReportAction.SPAM]: { info: 'notifications.willBeMovedToSpam', error: 'errors.moveToSpam' },
  [ReportAction.DELETE]: { info: 'notifications.willBeDeleted', error: 'errors.deleteEmail' },
  [ReportAction.NOTHING]: { info: '', error: '' },
};

export const handleReportResultAction = async (
  action: ReportAction,
  mailId: string,
  userEmail: string,
  showToast: (translationKey: string, options?: any) => void,
) => {
  const notifications = actionNotificationMap[action];
  try {
    switch (action) {
      case ReportAction.NOTHING:
        break;

      case ReportAction.SPAM:
        showToast(notifications.info, { intent: 'info' });
        setTimeout(async () => {
          await moveMailToSpam(mailId, userEmail);
        }, DELAY);
        break;

      case ReportAction.DELETE: {
        showToast(notifications.info, { intent: 'info' });
        setTimeout(async () => {
          await deleteMail(mailId, userEmail);
        }, DELAY);
        break;
      }

      default:
        break;
    }
  } catch (error) {
    handleError(error, () => showToast(notifications.error));
  }
};
