import React from 'react';
import { makeStyles, shorthands, Text, Button, Link } from '@fluentui/react-components';
import { MailIcon, PhishedLogoIcon } from '@utils-global';
import { Redirect } from 'react-router-dom';
import { paths } from '@constants';
import { useAuthStorage } from '@data';
import { useAutofocus } from '@utils';
import { useTranslation } from 'react-i18next';
import { useLogin } from './hooks';

const useStyles = makeStyles({
  wrapper: {
    ...shorthands.padding('16px'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    marginTop: '16px',
    marginBottom: '8px',
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
  },
  button: {
    marginTop: '16px',
    backgroundColor: '#50BCBD',
    width: '100%',
    ...shorthands.padding('10px'),
  },
  link: {
    marginTop: '20px',
    textAlign: 'center',
    color: '#50BCBD',
  },
});

export const LoginPage = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { targetRef } = useAutofocus();
  const { login, isSSOLoading } = useLogin();
  const { accessToken, graphToken } = useAuthStorage();

  // After login we need to redirect user to report page
  if (accessToken && graphToken) return <Redirect to={`/${paths.report}`} />;
  return (
    <section aria-label="Login page" className={styles.wrapper} tabIndex={-1} ref={targetRef}>
      <PhishedLogoIcon aria-hidden="true" />
      <MailIcon aria-hidden="true" />
      <Text color="#14141E" size={500} weight="bold" role="heading" className={styles.title}>
        {t('loginPage.title')}
      </Text>
      <Text color="#14141E" size={300} weight="bold" className={styles.subtitle}>
        {t('loginPage.subtitle')}
      </Text>
      <Button
        id="login-button"
        onClick={login}
        appearance="primary"
        disabled={isSSOLoading}
        className={styles.button}
      >
        {t('loginPage.button')}
      </Button>
      <Link
        className={styles.link}
        id="phished-website-link"
        href="https://phished.io/"
        target="_blank"
      >
        <Text color="#50BCBD" size={300} weight="semibold">
          {t('loginPage.link')}
        </Text>
      </Link>
    </section>
  );
};
