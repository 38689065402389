import { isDevelopment } from '@constants';

type LoginSSOParams = {
  setAccessToken: (value: string) => void;
};

export const loginSSO = async ({ setAccessToken }: LoginSSOParams) => {
  // Get the access token from Office host using SSO.
  const token = await OfficeRuntime.auth.getAccessToken({
    allowSignInPrompt: true,
    allowConsentPrompt: true,
    // In development mode it'll broke SSO with 13012 error
    // Actually this check is not a 100% correct when we're checking for 'isDevelopment' it means that the manifest was side loaded
    // It shouldn't depend on mode TARGET_ENV, but there's not option to check programmatically if add-in was side loaded
    // https://learn.microsoft.com/en-us/office/dev/add-ins/develop/authorize-to-microsoft-graph#details-on-sso-with-an-outlook-add-in
    forMSGraphAccess: !isDevelopment,
  });

  setAccessToken(token);
  return token;
};
