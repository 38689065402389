import { useAuthStorage } from '@data';
import { useEffect, useState } from 'react';

export const useHydration = () => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    const unsubFinishHydration = useAuthStorage.persist.onFinishHydration(() => setHydrated(true));

    setHydrated(useAuthStorage.persist.hasHydrated());

    return () => {
      unsubFinishHydration();
    };
  }, []);

  return hydrated;
};
