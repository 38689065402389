export const handleSSOErrors = (
  error,
  showToast: (translationKey: string, options?: any) => void,
) => {
  switch (error.code) {
    case 13001:
      // No one is signed into Office. If the add-in cannot be effectively used when no one
      // is logged into Office, then the first call of getAccessToken should pass the
      // `allowSignInPrompt: true` option. Since this sample does that, you should not see
      // this error.
      showToast('errors.sso.13001');
      break;
    case 13002:
      // The user aborted the consent prompt. If the add-in cannot be effectively used when consent
      // has not been granted, then the first call of getAccessToken should pass the `allowConsentPrompt: true` option.
      showToast('errors.sso.13002');
      break;
    case 13006:
      // Only seen in Office on the web.
      showToast('errors.sso.13006');
      break;
    case 13008:
      // Only seen in Office on the web.
      showToast('errors.sso.13008');
      break;
    case 13010:
      // Only seen in Office on the web.
      showToast('errors.sso.13010');
      break;
    default:
      // For all other errors, including 13000, 13003, 13005, 13007, 13012, and 50001
      // Means that SSO doesn't work on this platform
      break;
  }
  // Return null for errors that show a message to the user.
  return null;
};
