import { createContext } from 'react';

export type SharedMailboxContextType = {
  isSharedMailbox: boolean;
  sharedProperties: Office.SharedProperties | null;
  originalUserEmail: string | null;
};

export const SharedMailboxContext = createContext<SharedMailboxContextType>({
  isSharedMailbox: false,
  sharedProperties: null,
  originalUserEmail: null,
});
