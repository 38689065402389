import { Container } from '@utils-global';
import { routes } from '../constants';

export const moveMailToSpam = async (mailId: string, userEmail: string) => {
  const res = await Container.graphClient.post(routes.office.moveMailToSpam(mailId, userEmail), {
    destinationId: 'junkemail',
  });

  return res;
};
