import { Dispatch, SetStateAction } from 'react';
import { handleError } from '@utils-global';
import { exchangeOBO, handleSSOErrors, loginSSO } from './sso';
import { loginMSAL } from './msal';

type LoginHelpers = {
  userEmail: string;
  setGraphToken: (value: string) => void;
  setAccessToken: (value: string) => void;
  setSSOLoading: Dispatch<SetStateAction<boolean>>;
  showToast: (translationKey: string, options?: any) => void;
};

export const login = async (helpers: LoginHelpers, isSSO = true) => {
  const { userEmail, setAccessToken, setGraphToken, showToast, setSSOLoading } = helpers;
  if (isSSO) {
    try {
      setSSOLoading(true);
      const accessToken = await loginSSO({ setAccessToken });
      await exchangeOBO({ showToast, setGraphToken, accessToken });
    } catch (error) {
      handleError(error, () => handleSSOErrors(error, showToast));
    } finally {
      setSSOLoading(false);
    }
  } else {
    try {
      // Get access token through MSAL fallback.
      loginMSAL({ setAccessToken, setGraphToken, showToast, userEmail });
    } catch (error) {
      handleError(error);
    }
  }
};
