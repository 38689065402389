import axios, { AxiosInstance } from 'axios';
import { graphErrorInterceptor } from './interceptors';

interface IGraphClient {
  api: AxiosInstance;
}

export class GraphClient implements IGraphClient {
  public api: AxiosInstance;

  constructor(graphToken: string) {
    this.api = axios.create({
      baseURL: process.env.GRAPH_API_BASE_URL,
      headers: {
        Authorization: graphToken,
      },
    });

    this.api.interceptors.response.use(undefined, graphErrorInterceptor);
  }
}
