import { Container } from '@utils-global';
import { routes } from '../constants';

export const validateAccessToken = async (
  accessToken: string,
): Promise<{
  graphToken: string;
}> => {
  const res = await Container.accessTokenValidatorClient.get(routes.accessTokenValidator.validate, {
    headers: {
      Authorization: accessToken,
    },
    params: {
      environment: process.env.TARGET_ENV,
    },
  });

  return res.data;
};
