import React from 'react';
import { createRoot } from 'react-dom/client';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HashRouter } from 'react-router-dom';
import { ToastsContextProvider } from '@utils';
import App from './app';

// Create a client
const queryClient = new QueryClient();

const rootElement: HTMLElement = document.getElementById('container');
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  root.render(
    <FluentProvider theme={webLightTheme}>
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <ToastsContextProvider>
            <App />
          </ToastsContextProvider>
        </HashRouter>
      </QueryClientProvider>
    </FluentProvider>,
  );
});

if ((module as any).hot) {
  (module as any).hot.accept('./app', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./app').default;
    root.render(NextApp);
  });
}
