import { useEffect, useState } from 'react';
import { useAuthStorage } from '@data';
import { useToastsContext } from '@utils';
import { getUserEmail } from '@utils-global';
import { isSafari } from '@constants';
import { login as loginHandler } from '../api';

export const useLogin = () => {
  const { showToast } = useToastsContext();
  const [isSSOLoading, setSSOLoading] = useState(false);
  const { setAccessToken, setGraphToken } = useAuthStorage();
  const userEmail = getUserEmail();

  // if SSO failed - ask user to login manually
  const login = () => {
    loginHandler(
      {
        showToast,
        setAccessToken,
        setGraphToken,
        setSSOLoading,
        userEmail,
      },
      false,
    );
  };

  useEffect(() => {
    // firstly trying to authenticate user with SSO if it's not Safari and requirements set is supported
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issue-on-Safari
    if (!isSafari) {
      loginHandler({ showToast, setAccessToken, setGraphToken, setSSOLoading, userEmail });
    }
  }, [isSafari]);

  return {
    login,
    isSSOLoading,
  };
};
