import { makeStyles, shorthands, Text } from '@fluentui/react-components';
import React from 'react';
import { Report } from '@utils-global';
import { useAutofocus } from '@utils';
import { reportTypeIconMap } from './constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    ...shorthands.padding('16px'),
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '24px',
  },
  title: {
    color: '#14141E',
  },
  description: {
    color: '#706F6F',
  },
});

type ReportResultProps = {
  report: Report;
};

export const ReportResult = ({ report: { Title, Description, Type } }: ReportResultProps) => {
  const styles = useStyles();
  const Icon = reportTypeIconMap[Type];
  const { targetRef } = useAutofocus();

  return (
    <section
      tabIndex={-1}
      ref={targetRef}
      className={styles.wrapper}
      aria-label="Report result page"
    >
      <Icon className={styles.icon} aria-hidden="true" />
      <Text weight="bold" color="#14141E" size={300} className={styles.title} role="heading">
        {Title}
      </Text>
      <Text weight="semibold" size={300} className={styles.description}>
        {Description}
      </Text>
    </section>
  );
};
