import React, { ReactElement } from 'react';
import { Button, Text, makeStyles, shorthands } from '@fluentui/react-components';
import { useAutofocus } from '@utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  wrapper: {
    ...shorthands.padding('16px'),
  },
  button: {
    marginTop: '16px',
    backgroundColor: '#50BCBD',
    width: '100%',
    ...shorthands.padding('10px'),
  },
});

type ReportProps = {
  handleReportMail: () => void;
};

export const Report = ({ handleReportMail }: ReportProps): ReactElement => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { targetRef } = useAutofocus();

  return (
    <section
      tabIndex={-1}
      ref={targetRef}
      className={styles.wrapper}
      aria-describedby="reportPageTitle"
    >
      <Text color="#14141E" size={300} weight="semibold" role="heading" id="reportPageTitle">
        {t('reportPage.main.title')}
      </Text>
      <Button
        id="report-button"
        appearance="primary"
        className={styles.button}
        onClick={handleReportMail}
      >
        {t('reportPage.main.button')}
      </Button>
    </section>
  );
};
