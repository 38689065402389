import React from 'react';
import { makeStyles, shorthands, Text, Divider } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
// import { tabs } from './constants';
// import { useTabs } from './hooks';

const useStyles = makeStyles({
  header: {
    ...shorthands.padding('16px'),
  },
  tab: {
    ...shorthands.flex(1),
    ':after': {
      width: '100%',
      left: 0,
      backgroundColor: '#4FBBBD',
    },
  },
  title: {
    color: '#14141E',
  },
});

export const Header = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  // const { isActive, ...tabsProps } = useTabs();

  return (
    <>
      <header className={styles.header}>
        <Text className={styles.title} weight="bold" size={300} role="heading">
          {t('header.title')}
        </Text>
      </header>
      {/* <TabList {...tabsProps} role="navigation">
        {tabs().map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            className={styles.tab}
            role="link"
            aria-label={`Go to ${label} tab`}
          >
            <Text
              weight={isActive(value) ? 'bold' : 'semibold'}
              color={isActive(value) ? '#1F3460' : '#4C5D80'}
            >
              {label}
            </Text>
          </Tab>
        ))}
      </TabList> */}
      <Divider />
    </>
  );
};
