/* eslint-disable no-underscore-dangle */
import { AxiosInstance } from 'axios';

export class Container {
  private static _phishedClient: AxiosInstance;

  private static _graphClient: AxiosInstance;

  private static _accessTokenValidatorClient: AxiosInstance;

  public static registerPhishedClient(instance: AxiosInstance) {
    this._phishedClient = instance;
  }

  public static registerGraphClient(instance: AxiosInstance) {
    this._graphClient = instance;
  }

  public static registerAccessTokenValidatorValidator(instance: AxiosInstance) {
    this._accessTokenValidatorClient = instance;
  }

  public static get phishedClient() {
    return this._phishedClient;
  }

  public static get graphClient() {
    return this._graphClient;
  }

  public static get accessTokenValidatorClient() {
    return this._accessTokenValidatorClient;
  }
}
