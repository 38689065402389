// types we need inside app
export type ReportBody = {
  reporterEmailAddress: string;
  email: string;
};

export type Option = {
  value: string;
  label: string;
};

export enum Namespace {
  translation = 'translation',
}

// value should match with folder name
export enum Language {
  English = 'en',
  Arabic = 'ar',
  Bulgarian = 'bg',
  Czech = 'cs',
  DutchBelgium = 'nl-BE',
  DutchNetherlands = 'nl-NL',
  German = 'de',
  Spanish = 'es',
  French = 'fr',
  Croatian = 'hr',
  Hungarian = 'hu',
  Italian = 'it',
  Polish = 'pl',
  Portuguese = 'pt',
  PortugueseBrazil = 'pt-BR',
  Russian = 'ru',
  Turkish = 'tr',
  Chinese = 'zh',
}
