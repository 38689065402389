import { isDevelopment } from '@constants';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const useSentryInit = () => {
  useEffect(() => {
    if (!isDevelopment) {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [
          new Sentry.BrowserTracing(),
          new Sentry.Replay(),
          new Sentry.Integrations.Breadcrumbs({ console: true }),
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.TARGET_ENV || 'production',
      });
    }
  }, []);
};
