import { AddInError, handleError } from '@utils-global';

type ProcessLoginMessageParams = {
  closeDialog: () => void;
  setGraphToken: (value: string) => void;
  setAccessToken: (value: string) => void;
  args: { message: string; origin: string };
  showToast: (translationKey: string, options?: any) => void;
};

export const processLoginMessage = async ({
  args,
  showToast,
  closeDialog,
  setGraphToken,
  setAccessToken,
}: ProcessLoginMessageParams) => {
  try {
    closeDialog();
    const messageFromDialog = JSON.parse(args.message);

    if (messageFromDialog.status === 'success') {
      const { accessToken } = messageFromDialog.result;
      setAccessToken(accessToken);
      // If user was  authenticated with fallback method
      // access token should be valid for graph service
      setGraphToken(accessToken);
    } else {
      throw new AddInError(`Unexpected auth error happened: ${JSON.stringify(messageFromDialog)}`);
    }
  } catch (error) {
    handleError(error, () => showToast('errors.fallback.general'));
  }
};
