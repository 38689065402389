import { isDevelopment } from '@constants';
import { transformOfficeLanguageToTranslationLanguage } from '@utils';
import { Language, Namespace } from '@utils-global';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';

export const useI18NextInit = () => {
  const [isI18NextInitialized, setIsI18NextInitialized] = useState(false);

  useEffect(() => {
    i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        debug: isDevelopment,
        ns: Object.values(Namespace),
        supportedLngs: Object.values(Language),
        lng: transformOfficeLanguageToTranslationLanguage(
          Office.context.displayLanguage || Office.context.contentLanguage,
        ),
        fallbackLng: Language.English,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
          requestOptions: {
            cache: 'no-store',
          },
        },
      })
      .then(() => setIsI18NextInitialized(true));
  }, []);

  return { isI18NextInitialized };
};
