import { getMailMIME, useReportMail as useReportMailAPI } from '@data';
import { useShareMailboxContext, useToastsContext } from '@utils';
import { useState } from 'react';
import { handleError, getUserEmail } from '@utils-global';
import { handleReportResultAction } from '../api';

export const useReportMail = () => {
  const { showToast } = useToastsContext();
  const [isLoading, setIsLoading] = useState(false);
  const { isSharedMailbox, sharedProperties, originalUserEmail } = useShareMailboxContext();
  // if it's shared mailbox we should use mailbox mail address instead of user email
  // if it's ordinary mailbox user email === mailbox email
  const mailboxEmail =
    isSharedMailbox && sharedProperties?.targetMailbox
      ? sharedProperties.targetMailbox
      : getUserEmail();

  // This item ID normalization is based on:
  // https://stackoverflow.com/questions/65021585/get-mime-mail-content-in-outlook-addin-via-graph
  const mailId = Office.context.mailbox.item?.itemId.replace(/\//g, '-').replace(/\+/g, '_');
  const { mutate: reportMail, isSuccess, data } = useReportMailAPI();

  const getMIME = async () => {
    try {
      const mimeContent = await getMailMIME(mailId, mailboxEmail);
      console.info({ mimeContent });
      return mimeContent;
    } catch (error) {
      console.error('Error happened during getting MIME content', { error });
      handleError(error, () => {
        // if the error code is "ErrorItemNotFound" it means that email was deleted or moved to other folder
        if (error?.response?.data?.error?.code === 'ErrorItemNotFound')
          showToast('notifications.emailWasDeleted', { intent: 'warning' });
        else showToast('errors.mime');
      });
      throw error;
    }
  };

  const handleReportMail = async () => {
    try {
      setIsLoading(true);
      const mimeContent = await getMIME();

      reportMail(
        {
          email: mimeContent,
          // Phished API needs to know who sent the request
          reporterEmailAddress: isSharedMailbox ? originalUserEmail : mailboxEmail,
        },
        {
          onSuccess: async ({ data: response }) => {
            setIsLoading(false);
            showToast('notifications.reportSent', { intent: 'success' });
            await handleReportResultAction(response.Action, mailId, mailboxEmail, showToast);
          },
          onError: () => {
            setIsLoading(false);
            showToast('errors.failedReport');
          },
        },
      );
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return { data, handleReportMail, isLoading, isSuccess };
};
