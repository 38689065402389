import { AddInError } from '@utils-global';
import { processDialogEvent } from './processDialogEvent';
import { processLoginMessage } from './processLoginMessage';

type loginMSALParams = {
  userEmail: string;
  setGraphToken: (value: string) => void;
  setAccessToken: (value: string) => void;
  showToast: (translationKey: string, options?: any) => void;
};
// login using dialog API
export const loginMSAL = ({
  userEmail,
  setAccessToken,
  setGraphToken,
  showToast,
}: loginMSALParams) => {
  Office.context.ui.displayDialogAsync(
    `${process.env.APP_BASE_URL}/login.html?email=${userEmail}`,
    {
      height: 60,
      width: 30,
      promptBeforeOpen: false,
    },
    (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const loginDialog = result.value;

        // listen to login
        loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, (args) => {
          processLoginMessage({
            showToast,
            setGraphToken,
            setAccessToken,
            closeDialog: loginDialog.close,
            args: args as { message: string; origin: string },
          });
        });
        // listen to login dialog interaction
        loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
      } else {
        throw new AddInError('Failed to open login dialog');
      }
    },
  );
};
