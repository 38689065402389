import { makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';

const useStyles = makeStyles({
  user: {
    backgroundColor: '#1F3460',
    color: '#fff',
    width: '90%',
    alignSelf: 'flex-end',
    ...shorthands.padding('12px'),
  },
  chat: {
    backgroundColor: '#FFF',
    width: '90%',
    ...shorthands.padding('12px'),
  },
});

type ChatMessageProps = {
  fromUser?: boolean;
  text: string;
};

export const ChatMessage = ({ fromUser, text }: ChatMessageProps) => {
  const styles = useStyles();
  return <li className={fromUser ? styles.user : styles.chat}>{text}</li>;
};
