import { AddInError } from '@utils-global';

export const processDialogEvent = (arg: { error: number; type: string }) => {
  switch (arg.error) {
    case 12002:
      throw new AddInError(
        'The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.',
      );
    case 12003:
      throw new AddInError(
        'The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.',
      );
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      throw new AddInError('The dialog was closed before login');
    default:
      throw new AddInError('Unknown error in dialog box.');
  }
};
