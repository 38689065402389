import React, { useEffect } from 'react';
import { paths } from '@constants';
import { useLogout, useToastsContext } from '@utils';
import { Redirect } from 'react-router-dom';

export const LogoutPage = () => {
  useLogout();
  const { showToast } = useToastsContext();

  useEffect(() => {
    showToast('notifications.loggedOut');
  }, []);

  return <Redirect to={paths.login} />;
};
