// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/.pnpm/html-loader@5.0.0_webpack@5.90.3/node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("./styles.css", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("./assets/loaderThumbNail.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("./assets/oldBrowser.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<!doctype html> <html lang=\"en\" data-framework=\"typescript\"> <head> <meta charset=\"UTF-8\"/> <meta http-equiv=\"X-UA-Compatible\" content=\"IE=Edge\"/> <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\"> <title>Phished Add-in</title> <link rel=\"preconnect\" href=\"https://fonts.googleapis.com\"> <link rel=\"preconnect\" href=\"https://fonts.gstatic.com\" crossorigin> <link href=\"https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap\" rel=\"stylesheet\"> <link href=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" rel=\"stylesheet\"/> <" + "script src=\"https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js\"><" + "/script> </head> <body> <div id=\"container\"></div> <div id=\"loader\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> </div> <div id=\"tridentmessage\" style=\"display:none\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" class=\"tridentmessage_icon\"/> <h2 class=\"tridentmessage_title\">Upgrade your Office</h2> <p>This add-in will not run in your version of Office. Please upgrade either to perpetual Office 2021 (or later) or to a Microsoft 365 account.</p> </div> <" + "script>if(-1!==navigator.userAgent.indexOf(\"Trident\")||-1!==navigator.userAgent.indexOf(\"Edge\")){var tridentMessage=document.getElementById(\"tridentmessage\"),loader=document.getElementById(\"loader\"),normalContainer=document.getElementById(\"container\");tridentMessage.style.display=\"block\",normalContainer.style.display=\"none\",loader.style.display=\"none\"}<" + "/script> </body> </html> ";
// Exports
export default code;