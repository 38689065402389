import { validateAccessToken } from '@data';
import { AddInError, handleError } from '@utils-global';

type ExchangeOBOParams = {
  accessToken: string;
  setGraphToken: (value: string) => void;
  showToast: (translationKey: string, options?: any) => void;
};

export const exchangeOBO = async ({ accessToken, setGraphToken, showToast }: ExchangeOBOParams) => {
  try {
    if (!accessToken) throw new AddInError('Access token is not defined');
    const res = await validateAccessToken(accessToken);
    setGraphToken(res.graphToken);
  } catch (error) {
    handleError(error, () => showToast('errors.obo'));
  }
};
