import React from 'react';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { useAutofocus } from '@utils';
import { ChatInput, ChatMessage } from './components';

const fakeMessages = [
  {
    fromUser: false,
    text: 'Hello! How may I help you today?',
  },
  {
    fromUser: true,
    text: 'What is Phishing?',
  },
  {
    fromUser: false,
    text: "Phishing is a form of social engineering and scam where attackers deceive people into revealing sensitive information or installing malware such as ransomware. Phishing attacks have become increasingly sophisticated and often transparently mirror the site being targeted, allowing the attacker to observe everything while the victim is navigating the site, and transverse any additional security boundaries with the victim. As of 2020, it is the most common type of cybercrime, with the FBI's Internet Crime Complaint Center reporting more incidents of phishing than any other type of computer crime.",
  },
];

const useStyles = makeStyles({
  messagesList: {
    listStyleType: 'none',
    backgroundColor: '#EDEDED',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '90vh',
    ...shorthands.gap('8px'),
    ...shorthands.padding('16px'),
  },
});

export const ChatPage = () => {
  const styles = useStyles();
  const { targetRef } = useAutofocus();

  return (
    <section aria-label="Chat page" tabIndex={-1} ref={targetRef}>
      <ul className={styles.messagesList}>
        {fakeMessages.map(({ text, fromUser }) => (
          <ChatMessage key={text} text={text} fromUser={fromUser} />
        ))}
      </ul>
      <ChatInput />
    </section>
  );
};
