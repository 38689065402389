export const routes = {
  report: {
    reportMail: '/emails/reports/client',
    // TODO: replace later
    reportFalsePositive: '/fake/route',
  },
  chat: {
    // TODO: replace later
    sendMessage: '/fake/route',
    getMessages: '/fake/route',
  },
  office: {
    deleteMail: (mailId: string, userEmail: string) => `/users/${userEmail}/messages/${mailId}`,
    getMailMIME: (mailId: string, userEmail: string) =>
      `/users/${userEmail}/messages/${mailId}/$value`,
    moveMailToSpam: (mailId: string, userEmail: string) =>
      `/users/${userEmail}/messages/${mailId}/move`,
  },
  accessTokenValidator: {
    validate: '/validate',
  },
};
