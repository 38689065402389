import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  accessToken: string | undefined;
  graphToken: string | undefined;
  setAccessToken: (value: string) => void;
  setGraphToken: (value: string) => void;
}

export const useAuthStorage = create<AuthState>()(
  persist(
    (set) => ({
      accessToken: undefined,
      graphToken: undefined,
      setAccessToken: (value) => set(() => ({ accessToken: value })),
      setGraphToken: (value) => set(() => ({ graphToken: value })),
    }),
    {
      name: 'auth',
    },
  ),
);
