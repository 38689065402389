import React from 'react';
import { makeStyles, shorthands, Text } from '@fluentui/react-components';
import { LoaderIcon } from '@utils-global';
import { useAutofocus } from '@utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  loader: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '24px',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    ...shorthands.padding('16px'),
  },
  title: {
    color: '#14141E',
  },
  description: {
    color: '#706F6F',
  },
});

export const ReportLoading = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { targetRef } = useAutofocus();

  return (
    <section
      tabIndex={-1}
      ref={targetRef}
      className={styles.loaderWrapper}
      aria-label="Report in progress page"
    >
      <LoaderIcon className={styles.loader} aria-hidden="true" />
      <Text weight="bold" className={styles.title} size={300} role="heading">
        {t('reportPage.loading.title')}
      </Text>
      <Text weight="semibold" size={300} className={styles.description}>
        {t('reportPage.loading.subtitle')}
      </Text>
    </section>
  );
};
