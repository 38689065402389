import React, { ReactNode } from 'react';
import { Toast, ToastTitle, Toaster, useId, useToastController } from '@fluentui/react-components';
import { t } from 'i18next';
import { ToastsContext } from '../contexts';

type Props = {
  children: ReactNode;
};

export const ToastsContextProvider = ({ children }: Props) => {
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);

  const showToast = (translationKey: string, options) =>
    dispatchToast(
      <Toast appearance="inverted">
        <ToastTitle>{t(translationKey)}</ToastTitle>
      </Toast>,
      { intent: 'error', pauseOnHover: true, timeout: 10000, ...options },
    );

  return (
    <ToastsContext.Provider value={{ showToast }}>
      <Toaster toasterId={toasterId} />
      {children}
    </ToastsContext.Provider>
  );
};
