import { useAuthStorage } from '@data';
import { initPrivateContainer, initPublicContainer } from '@utils-global';
import { useEffect } from 'react';

export const useContainerInit = () => {
  const { graphToken } = useAuthStorage();

  useEffect(() => {
    initPublicContainer();
  }, []);

  useEffect(() => {
    if (graphToken) {
      initPrivateContainer(graphToken);
    }
  }, [graphToken]);
};
