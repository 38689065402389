import { AccessTokenValidatorClient, GraphClient, PhishedClient } from '@data';
import { Container } from './container';

export const initPublicContainer = () => {
  Container.registerPhishedClient(PhishedClient.api);
  Container.registerAccessTokenValidatorValidator(AccessTokenValidatorClient.api);
};

// some clients we have require the access tokens which we will get
// after authentication. That's why we init this part of container later
export const initPrivateContainer = (graphToken: string) => {
  Container.registerGraphClient(new GraphClient(graphToken).api);
};
