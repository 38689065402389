import { useEffect, useRef } from 'react';

export const useAutofocus = () => {
  const targetRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (targetRef.current) targetRef.current.focus();
  }, []);

  return { targetRef };
};
