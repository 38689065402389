import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { SharedMailboxContext, SharedMailboxContextType } from '../contexts';

type Props = {
  children: ReactNode;
};

// Should be used after Office is initialized
export const SharedMailboxContextProvider = ({ children }: Props) => {
  const [originalUserEmail, setOriginalUserEmail] = useState<string | null>(null);
  const [sharedProperties, setSharedProperties] = useState<Office.SharedProperties | null>(null);

  const isSharedMailbox = useMemo(
    () => !!Office.context.mailbox.item.getSharedPropertiesAsync,
    [!!Office.context.mailbox.item.getSharedPropertiesAsync],
  );

  const getSharedMailboxInfo = () => {
    Office.context.mailbox.getCallbackTokenAsync(
      {
        isRest: true,
      },
      ({ value, status }) => {
        if (status === Office.AsyncResultStatus.Succeeded && value !== '') {
          Office.context.mailbox.item.getSharedPropertiesAsync(
            {
              asyncContext: value,
            },
            (sharedPropertiesResponse) => {
              if (sharedPropertiesResponse.status === Office.AsyncResultStatus.Succeeded) {
                setSharedProperties(sharedPropertiesResponse.value);
                // We need to save original user email for shared mailboxes since it's not accessible
                // via Office.context.mailbox.userProfile.emailAddress ===  the shared mailbox email address
                const { smtp } = jwtDecode<{ smtp: string }>(sharedPropertiesResponse.asyncContext);
                setOriginalUserEmail(smtp);
              }
            },
          );
        }
      },
    );
  };

  useEffect(() => {
    if (isSharedMailbox) {
      getSharedMailboxInfo();
    }
  }, [isSharedMailbox]);

  const value: SharedMailboxContextType = useMemo(
    () => ({ isSharedMailbox, sharedProperties, originalUserEmail }),
    [isSharedMailbox, sharedProperties, originalUserEmail],
  );

  return <SharedMailboxContext.Provider value={value}>{children}</SharedMailboxContext.Provider>;
};
