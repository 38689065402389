import { Language } from '@utils-global';

type OfficeLanguageNormalLanguageMap = {
  [key: string]: Language;
};
const officeLanguageNormalLanguageMap: OfficeLanguageNormalLanguageMap = {
  en: Language.English,
  'en-US': Language.English,
  'en-GB': Language.English,
  'en-AU': Language.English,
  'en-CA': Language.English,
  'en-NZ': Language.English,
  ar: Language.Arabic,
  'ar-SA': Language.Arabic,
  'ar-EG': Language.Arabic,
  'ar-MA': Language.Arabic,
  'ar-DZ': Language.Arabic,
  bg: Language.Bulgarian,
  'bg-BG': Language.Bulgarian,
  nl: Language.DutchBelgium,
  'nl-BE': Language.DutchBelgium,
  'nl-NL': Language.DutchNetherlands,
  cs: Language.Czech,
  'cs-CZ': Language.Czech,
  de: Language.German,
  'de-AT': Language.German,
  'de-DE': Language.German,
  'de-LI': Language.German,
  'de-LU': Language.German,
  'de-CH': Language.German,
  es: Language.Spanish,
  'es-ES': Language.Spanish,
  fr: Language.French,
  'fr-FR': Language.French,
  'fr-CA': Language.French,
  'fr-BE': Language.French,
  'fr-LU': Language.French,
  'fr-CH': Language.French,
  hr: Language.Croatian,
  'hr-HR': Language.Croatian,
  hu: Language.Hungarian,
  'hu-HU': Language.Hungarian,
  it: Language.Italian,
  'it-IT': Language.Italian,
  'it-CH': Language.Italian,
  'it-SW': Language.Italian,
  'it-VA': Language.Italian,
  pl: Language.Polish,
  'pl-PL': Language.Polish,
  pt: Language.Portuguese,
  'pt-BR': Language.Portuguese,
  'pt-PT': Language.PortugueseBrazil,
  ru: Language.Russian,
  'ru-RU': Language.Russian,
  tr: Language.Turkish,
  'tr-TR': Language.Turkish,
  'tr-CY': Language.Turkish,
  zh: Language.Chinese,
  'zh-CN': Language.Chinese,
  'zh-HK': Language.Chinese,
  'zh-TW': Language.Chinese,
};

export const transformOfficeLanguageToTranslationLanguage = (officeLanguage: string): Language =>
  officeLanguageNormalLanguageMap[officeLanguage] || officeLanguageNormalLanguageMap['en-US'];
