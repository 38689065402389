// types API use
export enum ReportType {
  THREAT = 'Threat',
  SAFE = 'Safe',
  ACTUAL_THREAT = 'ActualThreat',
  SIMULATION = 'Simulation',
}

export enum ReportAction {
  NOTHING,
  SPAM,
  DELETE,
}

export type Report = {
  Type: ReportType;
  Title: string;
  Description: string;
  Color: string;
  Action: ReportAction;
};
