import { useAuthStorage } from '@data';
import { useEffect } from 'react';

export const useLogout = () => {
  const { accessToken, setAccessToken, graphToken, setGraphToken } = useAuthStorage();

  useEffect(() => {
    // Logout user
    if (accessToken) setAccessToken(undefined);
    if (graphToken) setGraphToken(undefined);
  }, []);
};
