import { useLogout } from '../useLogout.hook';
import { useSentryInit } from './useSentryInit.hook';
import { useContainerInit } from './useContainerInit.hook';
import { useHideLoader } from './useHideLoader.hook';
import { useI18NextInit } from './useI18nextInit.hook';

// Here we do everything we need to init app
export const useInitApp = () => {
  const { isI18NextInitialized } = useI18NextInit();

  useSentryInit();
  useLogout();
  useContainerInit();
  useHideLoader(isI18NextInitialized);

  return { isI18NextInitialized };
};
