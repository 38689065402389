import { paths } from '@constants';
import { useAuthStorage } from '@data';
import { useHydration } from '@utils';
import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

type PrivateComponentProps = {
  children: ReactNode;
};

export const PrivateComponent = ({ children }: PrivateComponentProps) => {
  const hasHydrated = useHydration();
  const { accessToken, graphToken } = useAuthStorage();

  if (!hasHydrated) return null;
  if (!accessToken || !graphToken) {
    return <Redirect to={`/${paths.login}`} />;
  }
  return <>{children}</>;
};
