import { makeStyles, shorthands, Text } from '@fluentui/react-components';
import { ErrorIcon } from '@utils-global';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    ...shorthands.padding('16px'),
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '24px',
  },
  title: {
    color: '#14141E',
  },
  description: {
    color: '#706F6F',
  },
  listTitle: {
    color: '#14141E',
    marginTop: '24px',
  },
  list: {
    marginLeft: '25px',
  },
  listItem: {
    color: '#706F6F',
  },
});

export const ErrorFallback = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const list: string[] = t('errorPage.list', { returnObjects: true }) || [];

  return (
    <section className={styles.wrapper} aria-label="Error page">
      <ErrorIcon className={styles.icon} aria-hidden="true" />
      <Text weight="bold" size={300} className={styles.title} role="heading">
        {t('errorPage.title')}
      </Text>
      <Text weight="semibold" size={300} className={styles.description}>
        {t('errorPage.subtitle')}
      </Text>

      <Text weight="semibold" size={300} className={styles.listTitle}>
        {t('errorPage.listTitle')}
      </Text>
      <ul className={styles.list}>
        {list.map((listItem) => (
          <li key={listItem}>
            <Text weight="semibold" size={300} className={styles.listItem}>
              {listItem}
            </Text>
          </li>
        ))}
      </ul>
    </section>
  );
};
