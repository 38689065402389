import React from 'react';
import { Report, ReportLoading, ReportResult } from './components';
import { useReportMail } from './hooks';

export const ReportPage = () => {
  const { data, handleReportMail, isLoading } = useReportMail();

  if (isLoading) return <ReportLoading />;
  if (data) return <ReportResult report={data.data} />;
  return <Report handleReportMail={handleReportMail} />;
};
