import { paths } from '@constants';
import { AxiosError, HttpStatusCode } from 'axios';

export const graphErrorInterceptor = (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === HttpStatusCode.Unauthorized) {
      // if the AT has expired - push to logout page
      window.location.hash = paths.logout;
    }
  }
};
